import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import WelcomeHeader from '../components/slides/thank-you/WelcomeHeader';

const ThankYou = () => {
  return (
    <Layout>
      <SEO
        title="Thank you | Deadline Funnel"
        description="Thanks for signing up!"
         meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <WelcomeHeader />
    </Layout>
  )
};

export default ThankYou;
